import { Fragment } from 'react';
import './App.css';
import Navbar from './Components/Navbar'
import Home from './Components/Home'
import Mission from './Components/Mission'
import Team from './Components/Team';
import Footer from './Components/Footer'

function App() {
  return (
    <Fragment>
      <header>
        <Navbar />
      </header>
      <Home />
      <Mission />
      <footer>
        <Footer />
      </footer>
    </Fragment>
  );
}

export default App;