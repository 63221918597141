import { Typography, Box} from '@mui/material';
import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CodeIcon from '@mui/icons-material/Code';
import Icon from '@mui/material/Icon';
import SvgIcon from '@mui/material/SvgIcon';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CloudIcon from '@mui/icons-material/Cloud';
import './styles.css';
import './styles.css';

const Home = () => {
  const style = {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 300,
      maxHeight: '60%',
      overflow: "scroll",
      ['@media (min-width:1025px)']: { // eslint-disable-line no-useless-computed-key
          width: 500
      },
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 2,
      color: '#1c3144'
    };
  return (
    <div className="Mission">
      <div className="Banner">
        Driving innovation with personalized tech solutions: Our team at Very Good Coding is committed to bridging the gap between technology and your business
        objectives, ensuring fluent communication and delivering bespoke solutions tailored to your needs.
      </div>
      <div>
      <Box className="Classes">
          <Box className="Offerings">
          <Card className="Card">
              <SvgIcon className="CardIcon"><CodeIcon/></SvgIcon>
              <CardHeader title="Custom Software Development " className="CardHeader"/>
              <CardContent>
                  <Typography variant="body1" className="CardText">
                    We create personalized software solutions that precisely fit your business needs. 
                  </Typography>
              </CardContent>
            </Card>
            <Card className="Card">
              <SvgIcon className="CardIcon"><EqualizerIcon/></SvgIcon>
              <CardHeader title="Data Analytics and Business Intelligence" className="CardHeader"/>
              <CardContent>
                  <Typography variant="body1" className="CardText">
                    Uncover actionable insights and drive informed decisions through our cutting edge data solutions. 
                  </Typography>
              </CardContent>
            </Card>
            <Card className="Card">
              <SvgIcon className="CardIcon"><CloudIcon/></SvgIcon>
              <CardHeader title="Cloud Solutions Consulting" className="CardHeader"/>
              <CardContent>
                  <Typography variant="body1" className="CardText">
                    Streamline your business operations with your tailored cloud solutions.
                  </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </div>
      
    </div>
  );
}
export default Home;