import * as React from 'react';
import Typography from '@mui/material/Typography';
import './styles.css';

function Home() {
  return (
    <div className="Team">
      <Typography variant="h4">Team Section</Typography>
    </div>
  );
}

export default Home;