import * as React from 'react';
import Typography from '@mui/material/Typography';
import './styles.css';

function Home() {
  return (
    <div className="Home">
      <Typography variant="h3">a tech team that speaks your language</Typography>
    </div>
  );
}
export default Home;