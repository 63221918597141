import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import VGCIcon from '../../Assets/vgc.svg';

function Navbar() {
  return (
    <AppBar position="static">
        <Toolbar>
          <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu">
              <img style={{width: "4em"}} src={VGCIcon} alt="homeIcon"/>
          </IconButton>
        </Toolbar>
    </AppBar>
  );
}
export default Navbar;