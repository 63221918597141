import React, { Fragment } from 'react'
import './styles.css'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    const handleClick = (link) => {
        switch(link) {
            case "twitter":
                window.open('https://www.twitter.com/verygoodcoding_');
              break;
            default:
                window.open('https://www.instagram.com/verygoodcoding/');
          }
    };

    return (
        <Fragment>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0}}>
                <Toolbar className="Footer">
                    <IconButton onClick={() => handleClick("twitter")}>
                        <FacebookIcon />
                    </IconButton>
                    <IconButton onClick={() => handleClick("instagram")}>
                        <InstagramIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar />  
        </Fragment>
    )
}

export default Footer